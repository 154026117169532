/*====================  
    4.5 Service CSS
====================*/
.service-area {
    border: 1px solid $gray-200;
    border-bottom: 0px;

    & .service-block {
        border-right: 1px solid $gray-200;
        border-bottom: 1px solid $gray-200;

        &:nth-child(4n + 0) {
            border-right: 0px;
        }
        @media #{$tablet-device} {
            &:nth-child(2n + 0) {
                border-right: 0px;
            }
        }
        @media #{$large-mobile} {
            &:nth-child(1n + 0) {
                border-right: 0px;
            }
        }
    }

    & .single-service {
        padding: 45px 30px 45px 40px;
        position: relative;
        display: block;
        overflow: hidden;

        @media #{$desktop-device, $extra-small-mobile} {
            padding: 20px;
        }

        & .service-icon {
            margin-bottom: 30px;
            z-index: 1;
            position: relative;
            & i {
                color: $creme;
                font-size: 46px;
            }
        }

        & .service-content {
            z-index: 1;
            position: relative;
            & .title {
                font-size: 20px;
                font-weight: 700;
                font-family: $font-family-base;
                color: $dark;
                margin-bottom: 15px;

                & a {
                    color: $dark;
                    &:hover {
                        color: $creme;
                    }
                }
            }
            & p {
                font-size: 16px;
                color: $gray-300;
                @media #{$desktop-device, $extra-small-mobile} {
                    font-size: 14px;
                }
            }

            & .more-link {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                font-size: 13px;
                font-weight: 500;
                letter-spacing: 2px;

                &:hover {
                    color: $creme;
                }

                & i {
                    font-size: 12px;
                    margin-left: 10px;
                }
            }
        }

        &::before {
            position: absolute;
            content: '';
            left: 0px;
            top: 0px;
            right: 0px;
            bottom: 0px;
            opacity: 0;
            background-color: rgb(246,242,242);
            transform: scale(0.1,1);
            transition: $transition-base;
        }

        &::after {
            position: absolute;
            content: '';
            left: 0px;
            top: 0px;
            right: 0px;
            opacity: 0;
            background-color: $primary;
            height: 6px;
            transition: $transition-base;
            transform: scale(0.1,1);
        }

        &:hover {
            &::before {
                transform: scale(1,1);
                transition: $transition-base;
                opacity: 1;
            }
            &::after {
                transition: $transition-base;
                opacity: 1;
                transform: scale(1,1);
            }
            & .service-content {
                & .more-link {
                    color: $creme;
                    &:hover {
                        color: $creme;
                    }
                }
            }
        }
    }
}