/*====================  
    5.3 Blog Page CSS
====================*/
/* Blog Background */
.blog-section-bg {
    background-image: url(../images/background/blog-bg.jpg);
    background-size: cover;
    padding: 180px 0px 180px;
    background-position: center;

    @media #{$desktop-device, $tablet-device} {
        padding: 150px 0;
    }

    @media #{$large-mobile} {
        padding: 100px 0;
    }


    & .blog-section-title {
        z-index: 1;
        position: relative;
        & .subtitle {
            color: $gray-700;
            font-size: 18px;
            line-height: 1.3em;
            letter-spacing: 8px;
            text-transform: uppercase;
            margin-bottom: 80px;
            font-family: $font-family-base;

            @media #{$desktop-device} {
                margin-bottom: 20px;
            }
            @media #{$tablet-device} {
                margin-bottom: 10px;
            }
            @media #{$large-mobile} {
                margin-bottom: 10px;
                letter-spacing: 4px;
                font-size: 16px;
            }
            @media #{$extra-small-mobile} {
                margin-bottom: 10px;
                letter-spacing: 4px;
                font-size: 14px;
            }
        }
    
        & .title {
            font-size: 100px;
            font-weight: 400;
            color: $white;
            font-family: $headings-font-family;
            line-height: 1;
            margin-bottom: 70px;
            text-transform: initial;
    
            @media #{$desktop-device, $tablet-device} {
                font-size: 70px;
                margin-bottom: 40px;
            }

            @media #{$large-mobile} {
                font-size: 50px;
                margin-bottom: 20px;
            }

            @media #{$small-mobile} {
                font-size: 40px;
                margin-bottom: 20px;
            }
            
            @media #{$extra-small-mobile} {
                font-size: 30px;
                margin-bottom: 15px;
            }
    
        }

        & p {
            font-size: 16px;
            color: $gray-700;
            width: 490px;
            text-align: center;
            margin: 0 auto;
            line-height: 1.9;

            @media #{$small-mobile} {
                font-size: 14px;
                width: 400px;
                line-height: 1.5;
            }
            @media #{$extra-small-mobile} {
                width: 280px;
                line-height: 1.5;
            }
        }
    }
}
/*-- Blog Slider --*/
.blog-slider{
    // Swiper Navigation
    & .blog-slider-prev, & .blog-slider-next {
        opacity: 1;
        visibility: visible;
        transition: $transition-base;
        color: $white;
    }
    & .blog-slider-prev{
        left: 20px;

        &:hover {
            color: $creme;
        }
    }
    & .blog-slider-next{
        right: 20px;

        &:hover {
            color: $creme;
        }
    }


    // Swiper Pagination

    & .swiper-pagination {
        position: relative;
        bottom: 0px;
        right: 0px;
        left: auto;
        text-align: center;
        margin-top: 20px;
    }
}
/*-- Hero Slider Content Animation --*/
.blog-section-title {
    & > * {
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: fadeOutUp;
    }
}
.swiper-slide-active{
    & .blog-section-title {

        // All Element Selector
        & > * {
            animation-name: fadeInUp;
            
            // Child Selector
            &:nth-child(1) {
                animation-delay: 0.9s;
            }
            &:nth-child(2) {
                animation-delay: 1s;
            }
            &:nth-child(3) {
                animation-delay: 1.2s;
            }
            
        }
    }
}
/* News Block */

.blog-block{
	position: relative;
	padding-bottom: 30px;
	margin-bottom: 30px;
    border-bottom: 1px solid #dddddd;
    
    & .blog-inner-box{
        position: relative;
        min-height: 194px;
        padding-left: 330px;

        @media #{$large-mobile} {
            padding-left: 0;
            min-height: 100%;
        }

        & .image{
            position: absolute;
            left: 0px;
            top: 0px;
            width: 300px;
            overflow: hidden;
            margin-bottom: 0px;
            background-color: $creme;

            @media #{$large-mobile} {
                width: 100%;
                position: relative;
                margin-bottom: 30px;
            }

            & img{
                position: relative;
                width: 100%;
                display: block;
                transition: all 500ms ease;
            }
        }

        &:hover {
            & .image {
                & img {
                    opacity: 0.7;
                    transform: scale(1.05,1.05) rotate(2deg);
                }
            }

            & .title {
                & a {
                    color: $creme;
                }
            }
        }

        & .subtitle{
            position: relative;
            color: $primary;
            font-size: 14px;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-family: $font-family-base;

            @media #{$large-mobile} {
                font-size: 12px;
            }
        }

        & .title {
            position: relative;
            line-height: 1.4em;
            font-weight: 400;
            margin-top: 10px;
            margin-bottom: 15px;
            font-size: 24px;

            & a{
                color: $dark;
            }

            @media #{$large-mobile} {
                font-size: 20px;
            }

        }

        & .post-date{
            position: relative;
            color: #666666;
            font-size: 14px;

            & span{
                position: relative;
                color: $dark;
            }
        }
    }
    &:last-child {
        border-bottom:none;
        padding-bottom:0px;
        margin-bottom:0px;
        min-height:auto;
    }
}

/* Pagination */
.styled-pagination{
	position:relative;
    margin-top:80px;

    @media #{$large-mobile} {
        margin-top: 50px;
    }
    
    & li{
        position: relative;
        margin-right: 35px;
        display: inline-block;

        @media #{$large-mobile} {
            margin-right: 20px;
        }

        & a{
            position: relative;
            color: $gray-400;
            font-size: 18px;
            font-weight: 400;
            display: inline-block;
            transition: all 0.3s ease;
        }
        &.active, &:hover {
            & a {
                color: $dark;
            }
        }

        &.next {
            & a {
                font-size: 14px;
                color: $dark;
            }
        }
    }
}

/* Search Box Widget */

.sidebar {
    & .search-box {
        & .form-group{
            position: relative;
            margin:0px;
            & input[type="text"],
            & input[type="search"]{
                position: relative;
                line-height: 30px;
                padding: 10px 50px 10px 20px;
                background: $gray-100;
                display: block;
                font-size: 14px;
                width: 100%;
                height: 50px;
                color: $gray-500;
                border-radius: 0px;
                transition: all 500ms ease;
                border: 0px;
                &::-webkit-input-placeholder{
                    color: $dark;
                }
            }

            & button{
                position: absolute;
                right: 0px;
                top: 0px;
                height: 50px;
                width: 50px;
                display: block;
                font-size: 16px;
                color: $dark;
                line-height: 52px;
                background: none;
                border-radius: 0px;
                cursor: pointer;
                border: 0px;
                transition: $transition-base;

                & .icon {
                    font-weight: 600;

                    &:hover {
                        color: $creme;
                        transition: $transition-base;
                    }
                }
            }

        }
    } 
}

.blog-cat{
    position:relative;
    
    & li{
        position:relative;

        & a{
            position: relative;
            color: $dark;
            font-size: 14px;
            padding: 12px 0px;
            display: block;
            z-index: 1;
            font-weight: 400;
            text-transform: uppercase;
            transition: all 500ms ease;
            border-bottom: 1px solid #dddddd;

            @media #{$large-mobile} {
                font-size: 14px;
                padding: 10px 0;
                font-weight: 500;
             }
        }
        &.active a, & a:hover {
            color: $creme;
            &::after {
                color: $creme;
                transition: $transition-base;
            }
        }

        &:last-child {
            & a {
                border-bottom: 0px;
            }
        }

        & span {
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            color: $dark;
        }
    }
}

.sidebar {
    & .popular-posts {
        & .widget-content{
            position: relative;
        }
        & .post{
            position: relative;
            font-size: 18px;
            color: $dark;
            padding: 0px 0px;
            padding-left: 100px;
            min-height: 75px;
            margin-bottom: 20px;
            border-bottom: 1px solid #d3d8dc;

            &:last-child {
                margin-bottom: 0px;
                border: none;
            }

            & .post-thumb {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 79px;
                overflow: hidden;
                border-radius: 0px;

                & img {
                    display: block;
                    width: 100%;
                    transition: all 0.3s ease;
                }
            }

            &:hover {
                & .post-thumb {
                    & img {
                        opacity: .70;
                    }
                }
            }

            & .text{
                position: relative;
                top: -4px;
                font-size: 18px;
                margin: 0px 0px 0px;
                font-weight: 400;
                color: $dark;
                line-height: 1.4em;
                text-transform: capitalize;
                @media #{$large-mobile, $desktop-device} {
                    font-size: 16px;
                }

                & a{
                    color: $dark;
                    transition: all 0.3s ease;

                    &:hover {
                        color: $creme;
                    }
                }
            }
        }
    } 
}

// Sidebar Tags
.sidebar-widget {
    & .widget-tag-content {
        & a {
            display: inline-block;
            margin-bottom: 10px;
            &:focus {
                box-shadow: none;
            }
        }
    }
}

.sidebar-blog {
    & .sidebar-widget {
        @media #{$large-mobile} {
            margin-bottom: 20px;
        }
    }
}

/* Single Blog Background */
.single-blog-bg {
    background-image: url(../images/background/single-blog-bg.jpg);
    background-size: cover;
    padding: 180px 0px 180px;
    background-position: center;

    @media #{$desktop-device, $tablet-device} {
        padding: 150px 0;
    }

    @media #{$large-mobile} {
        padding: 100px 0;
    }


    & .single-blog-title {
        z-index: 1;
        position: relative;
        & .title {
            font-size: 60px;
            font-weight: 400;
            color: $white;
            font-family: $headings-font-family;
            line-height: 1.2;
            margin-bottom: 70px;
            text-transform: initial;
    
            @media #{$desktop-device, $tablet-device} {
                font-size: 40px;
                margin-bottom: 40px;
            }

            @media #{$large-mobile} {
                font-size: 30px;
                margin-bottom: 20px;
                & br {
                    display: none;
                }
            }
            
            @media #{$extra-small-mobile} {
                font-size: 30px;
                margin-bottom: 15px;
            }
        }

        & .post-info{
            position:relative;

            & li{
                position:relative;
                line-height:1em;
                color:#ffffff;
                padding-right:15px;
                margin-right:15px;
                display:inline-block;
                text-transform:uppercase;
                border-right:1px solid #8f8f8f;

                &:last-child {
                    position:relative;
                    padding-right:0px;
                    margin-right:0px;
                    border-right:0px;
                    color: $creme;
                }
            }
        }
    }
}
/* Blog Detail */
.blog-detail{
    position:relative;
    & .inner-box{
        position:relative;
        padding-right:70px;

        @media #{$desktop-device} {
            padding-right: 30px;
        }

        @media #{$large-mobile, $tablet-device} {
            padding-right: 0;
        }

        & .post-info{
            position:relative;
            margin-bottom:40px;

            & li{
                position:relative;
                line-height:1em;
                color:#666666;
                font-size:14px;
                padding-right:12px;
                margin-right:12px;
                display:inline-block;
                text-transform:uppercase;

                &::before {
                    position:absolute;
                    content:'/';
                    right:-4px;
                    top:0px;
                    color:#666666;
                }

                & a{
                    position:relative;
                    line-height:1em;
                    color:#666666;
                }

                &:last-child {
                    position:relative;
                    padding-right:0px;
                    margin-right:0px;
                    border-right:0px;
                    color:#000000;
                    &::before {
                        display: none;
                    }
                }
            }
        }
        & .image{
            position:relative;
            margin-bottom:40px;

            & img{
                position:relative;
                width:100%;
                display:block;
            }
        }
        & p{
            position:relative;
            color:#666666;
            line-height:1.8em;
            margin-bottom:25px;
        }

        & .title{
            position:relative;
            color:#000000;
            font-weight:700;
            line-height:1.3em;
            margin-bottom:25px;
            font-family: 'DM Sans', sans-serif;
        }

        & blockquote{
            position:relative;
            margin:60px 0px 50px;
            padding-left:105px;

            @media #{$large-mobile} {
                padding-left: 40px;
                margin:40px 0px 30px;
            }

            & .blockquote-text{
                position:relative;
                color:#000000;
                font-weight:400;
                font-size:30px;
                line-height:1.3em;
                font-family: $headings-font-family;

                @media #{$large-mobile} {
                    font-size: 24px;
                    & br {
                        display: none;
                    }
                }

                & .quote{
                    position:absolute;
                    left:-20px;
                    top:-5px;
                    color:#000000;
                    font-size:16px;
                    line-height:1em;
                }
            }
        }

        & .post-share-options{
            position:relative;
            margin-top: 70px;
            padding-bottom:60px;
            border-bottom:1px solid #dddddd;

            @media #{$large-mobile} {
                margin-top: 55px;
            }

            & .tags {
                margin-bottom: -10px;
                & a {
                    position:relative;
                    color:#999999;
                    font-size:14px;
                    font-weight:400;
                    line-height: 1em;
                    margin-right: 4px;
                    padding:10px 20px;
                    display: inline-block;
                    border:1px solid #d7d7d7;
                    text-transform:capitalize;
                    transition:all 500ms ease;
                    margin-bottom: 10px;

                    &:hover {
                        color:#ffffff;
                        border-color:#294148;
                        background-color:#294148;
                    }
                }
            }
        }
    }
    & .related-projects {
        margin-top: 75px;
        padding-right: 80px;

        @media #{$large-mobile, $tablet-device} {
            padding-right: 0;
        }

        @media #{$large-mobile} {
            margin-top: 55px;
            margin-bottom: 30px;
        }

        & .blog-block {
            margin-bottom: 0;
            padding-bottom: 0;
            @media #{$tablet-device} {
                margin-bottom: 0;
                padding-bottom: 50px;
            }

            @media #{$large-mobile} {
                &:first-child {
                    margin-bottom: 30px;
                }
            }

        }
    }
}
/* Blog Replay Form */
.replay-form {
    width: 790px;
    left: 0;
    right: auto;
    @media #{$tablet-device, $large-mobile} {
        width: 100%;
    }
}

.form-boxed {
    & .form-title {
        margin-bottom: 40px;
        & .title {
            font-size: 30px;
            font-weight: 400;
            font-family: $headings-font-family;
            line-height: 1;
        }

        & p {
            color: $gray-300;
        }
    }
}