/*====================  
    4.4 CTA CSS
====================*/
.cta-bg {
    position: relative;
    background-image: url(../images/background/bg-1.jpg);
    padding: 62px 0;
    background-size: cover;

    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: $primary;
        opacity: .75;
        top: 0;
        left: 0;
    }
}
.booking-content-number {
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media #{$tablet-device, $large-mobile} {
        justify-content: center;
    }

    .booking-content {
        @media #{$tablet-device, $large-mobile} {
            text-align: center;
            margin-bottom: 20px;
        }

        & .title {
            color: $white;
            font-family: $font-family-base;
            font-weight: 400;
            font-size: 30px;
            @media #{$desktop-device} {
                font-size: 26px;
            }
            @media #{$tablet-device, $large-mobile} {
                font-size: 24px;
            }
            @media #{$small-mobile} {
                font-size: 23px;
            }
            
            @media #{$extra-small-mobile} {
                font-size: 20px;
            }
            & a {
                font-weight: 600;
                color: $white;

                &:hover {
                    color: $creme;
                }
            }
        }

        & p {
            color: $white;
            font-family: $font-family-base;
            font-weight: 400;
            font-size: 16px;
            
            @media #{$large-mobile} {
                font-size: 14px;
            }
        }
    }

    & .contact-number {
        display: flex;
        align-items: center;
        & a {
            font-size: 48px;
            font-family: $font-style-1;
            color: $creme;
            font-weight: 700;
            @media #{$desktop-device} {
                font-size: 38px;
            }
            
            @media #{$tablet-device, $large-mobile} {
                font-size: 30px;
            }
        }
    }
}