/*----------------------------------------*/
/*  06. Footer CSS
/*----------------------------------------*/
.main-footer{

    & .footer-widget{
        position: relative;
    }

    & .widgets-section{
        position: relative;

        & .title {
            position: relative;
            font-weight: 600;
            color: $white;
            line-height: 1.2em;
            margin-bottom: 25px;
            text-transform: capitalize;
            font-family: $font-family-base;
        }
    }

    & .logo-widget {
        & .call{
            position: relative;
            color: $gray-400;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.8em;
            text-transform:uppercase;
            margin-top: 50px;

            & .phone{
                color: $white;
                font-size:24px;
                display:block;
                margin-top:15px;
                margin-bottom:7px;
                transition:all 500ms ease;

                &:hover {
                    color: $creme;
                }
            }
            & .email{
                color: $white;
                font-size:16px;
                display:block;
                margin-top:0px;
                text-transform: initial;
                transition:all 500ms ease;
                
                &:hover {
                    color: $creme;
                }
            }
        }
    }

    & .newsletter-widget {
        & .text{
            position: relative;
            color: $gray-400;
            font-size: 14px;
            line-height: 1.8em;
            margin-bottom: 15px;
        }

        & .newsletter-form{
            margin-top: 35px;

            & .form-group{
                position: relative;
                display: block;
                margin: 0px;
                width: 100%;
                max-width: 550px;

                & input[type="text"],
                & input[type="tel"],
                & input[type="email"],
                & textarea{
                    position: relative;
                    display: block;
                    width: 100%;
                    line-height: 28px;
                    height: 44px;
                    font-size: 14px;
                    color: $white;
                    font-weight: 400;
                    padding: 10px 20px 10px 20px;
                    background: #363433;
                    border-radius: 3px;
                    transition: all 300ms ease;
                    border: 0;

                    &:focus {
                        border-color: $creme;
                    }
                }

                & input[type="email"]{
                    padding-right: 40px;

                    &::placeholder{
                        color: #999;
                        opacity: 1;
                    }
                }
                & input[type="submit"], button {
                    position:absolute;
                    right:0px;
                    top:0px;
                    height:44px;
                    line-height:44px;
                    color: $white;
                    font-size:20px;
                    text-align:center;
                    background:none;
                    width:40px;
                    cursor:pointer;
                    background:none;
                    text-transform:capitalize;
                    border: 0;

                    &:hover {
                        color: $creme;
                    }
                }
            }
        }
    }

    & .footer-bottom{
        position: relative;

        & .bottom-inner{
            position: relative;
            padding: 55px 0px;
            border-top: 1px solid #3e3b3a;

            @media #{$large-mobile} {
                padding: 40px 0px;
            }
        }

        & .copyright{
            position: relative;
            color: $gray-400;
            font-size: 16px;

            @media #{$large-mobile} {
                text-align: center;
            }

            @media #{$extra-small-mobile} {
                font-size: 14px;
            }

            & a {
                position: relative;
                font-weight: 600;
                &:hover {
                    & span {
                        color: $creme;
                        transition: $transition-base;
                    }
                }
            }

            & span {
                color: $white;
                font-weight: 600;
                transition: $transition-base;
            }
        }

        & .social-nav{
            position: relative;
            text-align: right;

            @media #{$large-mobile} {
                text-align: center;
            }

            & li{
                position: relative;
                margin-left: 10px;
                display: inline-block;

                & a{
                    position: relative;
                    color: $gray-400;
                    font-size: 16px;
                    transition: all 300ms ease;

                    &:hover {
                        color: $white;
                    }
                }
            }
        }
    }

}

/* List Link */

.list-link {
    & li{
        position: relative;
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }

        & a{
            position:relative;
            color: $gray-400;
            font-size: 14px;
            font-weight: 400;
            transition: all 300ms ease;

            &:hover {
                color: $creme;
                padding-left: 5px;
            }
        }
    }
} 
