/*====================  
    4.9 News CSS
====================*/
.news-block {
    height: 100%;
    position: relative;



    &::after {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        height: 100%;
        width: 1px;
        background: #e1e1e1;
        z-index: -1;
    }

    @media #{$large-mobile} {
        border-right: 0px;

        &::after {
            display: none;
        }
    }
    & .image {
        & a {
            display: block;
            overflow: hidden;
            & img {
                transition: $transition-base;
            }
        }
    }
    &:hover {
        & .image {
            & a {
                & img {
                    transform: scale(1.1) rotate(1deg);
                    transition: $transition-base;
                }
            }
        }
        & .lower-content {
            & .title {
                & a {
                    color: $creme;
                }
            }
        }
    }

    .lower-content {
        position: relative;
        padding: 45px 40px;
    
        @media #{$large-mobile} {
            padding: 30px 15px;
        }
    
        & .info-list {
            position: relative;
    
            & li {
                position: relative;
                color: $gray-300;
                font-size: 14px;
                margin-right: 10px;
                padding-right: 12px;
                display: inline-block;
                text-transform: uppercase;
    
                &::before {
                    position: absolute;
                    content: '';
                    right: -4px;
                    top: 10px;
                    width: 4px;
                    height: 4px;
                    border-radius: 50px;
                    display: inline-block;
                    background-color: $gray-300;
                }
    
                &:last-child {
                    color: $primary;
                    &::before {
                        display: none;
                    }
                }
            }
        }
    
        & .title {
            font-family: $font-family-base;
            font-size: 23px;
            font-weight: 600;
            line-height: 1.4;
            margin-top: 10px;
    
            @media #{$large-mobile} {
                font-size: 20px;
            }
        }
    
    
    }
}
.news-block-wrapper {

    &:nth-child(4n + 0){
        & .news-block {
            &::after {
                display: none;
            }
        }
    }

    @media #{$desktop-device, $tablet-device} {
        &:nth-child(4n + 2){
            & .news-block {
                &::after {
                    display: none;
                }
            }
        }
     }

}