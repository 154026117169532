/*====================  
    4.8 Client CSS
====================*/
.single-client-wrapper {
    border: 1px solid $border-color;
    padding: 50px;
    position: relative;
    @media #{$large-mobile} {
        padding: 40px;
    }
    @media #{$small-mobile} {
        padding: 30px;
    }
    @media #{$extra-small-mobile} {
        padding: 20px;
    }

    & .client-icon {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-bottom: 10px;

        & .icon {
            & i {
                font-size: 36px;
                color: $primary;
                @media #{$large-mobile} {
                    font-size: 30px;
                }
                @media #{$small-mobile} {
                    font-size: 26px;
                }
                @media #{$extra-small-mobile} {
                    font-size: 24px;
                }
            }
        }
    }

    & .client-content {
        & .name {
            margin-bottom: 25px;
            & a {
                font-family: $font-family-base;
                font-weight: 600;
                font-size: 20px;
                color: $dark;

                &:hover {
                    color: $primary;
                }
            }
            & span {
                font-weight: 400;
                color: $gray-400;
            }
        }

        & p {
            font-weight: 400;
            color: $gray-300;
        }
    }

    &::before {
        position: absolute;
        width: 0;
        height: 4px;
        background: $primary;
        content: "";
        top: -1px;
        left: 50%;
        transform: translateX(-50%);
        transition: .6s;
    }

    &:hover {
        &::before {
            width: 100%;
            transition: .6s;
        }
    }
}
.client-title {
    & .title {
        @media #{$extra-small-mobile}{
            font-size: 20px;
            padding-right: 70px;
        }
    }

}
.client-crousel {
    & .client-crousel-prev, & .client-crousel-next {
        opacity: 0;
        visibility: hidden;
        transition: $transition-base;

        &::after {
            display: none;
        }

        &:focus {
            outline: 0;
        }

        & i{ 
            opacity: 1;
            visibility: visible;
            font-size: 30px;
            color: $dark;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            font-weight: 600;
            &:hover{
                color: $primary;
                transition: .3s;
            }
        }
    }

    &:hover {
        & .client-crousel-prev, & .client-crousel-next {
            opacity: 1;
            visibility: visible;
            transition: $transition-base;
        }
    }
}