/*====================  
    4.6 Reputation CSS
====================*/
.reputation-form {
        position: relative;

        & .title {
            position: relative;
            color: $dark;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 35px;
            text-transform: uppercase;
            font-family: $font-family-base;
        }

        & .inner-column{
            position: relative;
        }

        & .form-boxed{
            position: relative;
            border-radius: 3px;
            padding: 55px 45px 0px;
            border: 1px solid #c0c8cd;
            background-color: $white;

            @media #{$small-mobile} {
                padding: 30px 20px 0;
                overflow: hidden;
            }
        }
}
.reputation-content {
    & .inner-column {
        padding-left: 170px;

        @media #{$desktop-device} {
            padding-left: 50px;
        }
        @media #{$tablet-device, $large-mobile} {
            padding-left: 0px;
        }
    }

    & .blocks-outer {
        padding-top: 20px;
        @media #{$large-mobile} {
            padding-top: 0px;
        }
        & .reputation-block {
            margin-bottom: 28px;
            &:last-child {
                margin-bottom: 0;
            }
            & .title {
                color: $dark;
                font-weight: 600;
                line-height: 1.3em;
                font-family: $font-family-base;
            }
            & p {
                color: $gray-300;
                line-height: 1.9em;
                margin-top: 15px;
            }
        }
    }
}