/*----------------------------------------*/
/*  03. Hero CSS
/*----------------------------------------*/
.hero-slide-item, 
.single-slider {
    position: relative;

    display: flex;
    align-items: center;

    height: 846px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    @media #{$tablet-device} {
        height: 700px;
    }
    @media #{$large-mobile} {
        height: 600px;
    }
    @media #{$extra-small-mobile} {
        height: 600px;
    }
}
.hero-slide-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    & img {
        width: 100%;
        height: 100%;

        object-fit: cover;
    }
}
.hero-slide-content {
    // position: relative;
    z-index: 9999;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    
    & .subtitle {
        font-weight: 400;
        line-height: 1em;
        font-size: 18px;
        letter-spacing: 8px;

        display: block;

        margin-bottom: 80px;
        text-transform: uppercase;

        color: $white;
        font-family: $font-family-base;
        @media #{$desktop-device} {
            margin-bottom: 60px;
        }
        @media #{$tablet-device} {
            margin-bottom: 40px;
        }

        @media #{$large-mobile} {
            margin-bottom: 20px;
            letter-spacing: 4px;
        }
    }
    & .title {
        font-size: 100px;
        font-weight: 400;
        line-height: 1em;
        color: $white;

        margin-bottom: 75px;


        text-transform: capitalize;
        @media #{$laptop-device, $desktop-device} {
            font-size: 90px;
            margin-bottom: 60px;
        }
        @media #{$tablet-device} {
            font-size: 80px;
            margin-bottom: 50px;
        }
        @media #{$large-mobile} {
            font-size: 64px;
            margin-bottom: 40px;
        }
        @media #{$extra-small-mobile} {
            font-size: 50px;
        }

        & span {
            color: $creme;
        }
    }
    & p {
        line-height: 1.9;
        font-family: $font-family-base;
        color: $gray-700;
        font-weight: 400;

        max-width: 530px;
        margin-bottom: 100px;
        @media #{$desktop-device} {
            margin-bottom: 80px;
        }
        @media #{$tablet-device} {
            margin-bottom: 60px;
            max-width: 500px;
        }
        @media #{$large-mobile} {
            margin-bottom: 30px;
            max-width: 400px;
        }
        @media #{$extra-small-mobile} {
            font-size: 14px;
            margin-bottom: 20px;
            max-width: 280px;
        }
    }
}
/*-- Swiper Hero Navigation & Pagination --*/
.hero-slider{
    // Swiper Navigation
    &:hover {
        & .home-slider-prev, & .home-slider-next {
            opacity: 1;
            visibility: visible;
            transition: $transition-base;
        }
        & .home-slider-prev{
            left: 20px;
        }
        & .home-slider-next{
            right: 20px;
        }
    }
    & .swiper-container{
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    & .home-slider-prev, & .home-slider-next{
        transition: $transition-base;
        font-size: 30px;
        color: $creme;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        &::after{
            display: none;
        }
        &:hover{
            color: $white;
            transition: $transition-base;
        }
        &:focus {
            outline: 0;
            border: 0;
        }
        & i {
            line-height: 1;
        }
    }
    & .home-slider-prev{
        right: auto;
        left: 0px;
    }
    & .home-slider-next{
        right: 0px;
        left: auto;
        &::after{
            display: none;
        }
    }
    // Swiper Pagination
    & .swiper-pagination {
        position: absolute;
        bottom: 30px;
        right: 0px;
        left: auto;
        text-align: center;
    }
}
/*-- Slick Slider Navigation & Pagination --*/
.slider-active {
    position: relative;
    .slide-arrow-left{
        left: 0;
        right: auto;
    }
    .slide-arrow-right{
        right: 0;
        left: auto;
    }
    .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 99;
        transition: all 0.3s ease 0s;
        font-size: 30px;
        color: $creme;
        opacity: 0;
        visibility: hidden;
        cursor: pointer;
        display: block;
        @media #{$large-mobile} {
            display: none;
        }
    }
    &:hover {
        .slick-arrow {
            opacity: 1;
            visibility: visible;
            transition: .3s;

            &:hover {
                color: $white;
            }
        }
        .slide-arrow-left{
            left: 20px;
        }
        .slide-arrow-right{
            right: 20px;
        }
    }

    & .slick-dots {
        position: absolute;
        bottom: 30px;
        display: flex;
        left: 50%;
        transform: translateX(-50%);
        display: none;
        opacity: 0;
        visibility: hidden;

        @media #{$large-mobile} {
            display: flex !important;
            opacity: 1;
            visibility: visible;
        }

        & li {
            margin-right: 10px;
            line-height: 1;
            & button {
                transition: .3s;
                border: 0px;
                background: $white;
                position: relative;
                width: 12px;
                height: 12px;
                display: inline-block;
                border-radius: 50%;
                font-size: 0;

                &:after {
                    transition: .3s;
                    position: absolute;
                    content: "";
                    height: 100%;
                    width: 100%;
                    background: $white;
                    left: 0;
                    top: 0;
                    border-radius: 50%;
                    overflow: hidden;
                }
            }

            &:last-child{
                margin-right: 0;
            }

            &:hover, &.slick-active {
                & button {
                    transition: .3s;
                    background: $creme;
                    &:after {
                        transition: .3s;
                        background: $creme; 
                    }
                }
            }
        }
    }
}
/*-- Hero Slider Content Animation --*/
.hero-slide-content {
    & > * {
        animation-duration: 1.5s;
        animation-fill-mode: both;
        animation-name: fadeOutUp;
    }
}
.swiper-slide-active, 
.slick-current.slick-active{
    & .hero-slide-content {

        // All Element Selector
        & > * {
            animation-name: fadeInUp;
            
            // Child Selector
            &:nth-child(1) {
                animation-delay: 0.9s;
            }
            &:nth-child(2) {
                animation-delay: 1s;
            }
            &:nth-child(3) {
                animation-delay: 1.2s;
            }
            &:nth-child(4) {
                animation-delay: 1.8s;
            }
            &:nth-child(5) {
                animation-delay: 2.5s;
            }
            &:nth-child(6) {
                animation-delay: 3s;
            }
            
        }
    }
}
/*-- Hero Slider Social --*/
.hero-slider-social {
    position: absolute;
    bottom: 60px;
    left: 70px;
    z-index: 99;

    @media #{$laptop-device,
         $desktop-device,
          $tablet-device} {
        bottom: 30px;
        left: 30px;
    }
    @media #{$large-mobile} {
       bottom: 20px;
       left: 30px
    }
    @media #{$small-mobile} {
        left: 15px
     }
}
