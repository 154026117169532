/*====================  
    5.4 Contact Page CSS
====================*/
/* Google map */
.google-map-area {
    & .contact-map {
        width: 100%;
        height: 550px;
        display: block;

        @media #{$desktop-device} {
           height: 500px;
        }
        @media #{$tablet-device} {
            height: 450px;
        }
        @media #{$large-mobile} {
            height: 380px;
        }
    }
}
/* Contact Info */
.contact-info-boxed{
    width: 970px;
    margin: 0 auto;
    @media #{$desktop-device} {
        width: 930px;
    }
    @media #{$tablet-device, $large-mobile} {
        width: 100%;
    }

    & .title {
        color: $dark;
        font-size:48px;
        font-weight:400;
        line-height:1.3em;
        margin-bottom:12px;
        text-align: center;


        @media #{$tablet-device} {
            font-size: 38px;
        }
        @media #{$large-mobile} {
            font-size: 30px;

            & br {
                display: none;
            }
        }
        @media #{$extra-small-mobile} {
            font-size: 28px
        }
    }

    & .info-column {
        padding: 0 30px;

        & .upper-box {
            position: relative;
            padding-bottom: 8px;
            margin-bottom: 25px;
            border-bottom: 1px solid #d3d6da;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            & .title {
                color: #243033;
                font-size: 18px;
                font-weight: 700;
                text-transform: uppercase;
                font-family: $font-family-base;
                margin-bottom: 0;
            }

            & .icon {
                font-size: 22px;
            }
        }

        & .info-list {
            & li {
                font-size: 16px;
                line-height: 1.8em;
                color: $gray-300;
            }
        }
    }
}
.form-boxed{
	position:relative;
    padding:70px 70px;
    
    @media #{$tablet-device} {
        padding: 60px 30px;
    }
    @media #{$large-mobile} {
        padding: 60px 30px;
    }
    @media #{$extra-small-mobile} {
        padding: 20px;
    }
    
    & .boxed-inner{
        position:relative;
    }
}
/* Contact Form */

.contact-form{
    & .form-group{
        position:relative;
        margin-bottom:30px;

        & input[type="text"],
        & input[type="password"],
        & input[type="tel"],
        & input[type="email"],
        & select{
            position:relative;
            display:block;
            width:100%;
            color:$dark;
            line-height:28px;
            padding:10px 20px;
            height:50px;
            font-size:14px;
            border-radius:0px;
            background-color: $white;
            border:1px solid $border-color;
            transition:all 300ms ease;

            &:focus {
                border-color: $primary;
            }
        }

        & textarea {
            position:relative;
            display:block;
            width:100%;
            line-height:24px;
            padding:15px 20px;
            color:$dark;
            height:210px;
            resize:none;
            font-size:14px;
            border-radius:0px;
            background-color:$white;
            border:1px solid $border-color;
            transition:all 300ms ease;
            &:focus {
                border-color: $primary;
            }
        }
    }
} 


// Contact Section Background
.contact-section-bg {
    background-image: url(../images/background/contact-bg.jpg);
    background-size: cover;
    padding: 260px 0px 260px;
    background-position: center;

    @media #{$desktop-device, $tablet-device} {
        padding: 150px 0;
    }

    @media #{$large-mobile} {
        padding: 100px 0;
    }

    & .contact-section-title {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 1;
        & .subtitle {
            color: $white;
            font-size: 18px;
            opacity: 0.75;
            letter-spacing: 10px;
            text-transform: uppercase;
            font-family: $font-family-base;
            font-weight: 400;

            @media #{$tablet-device} {
                letter-spacing: 6px;
            }
            @media #{$large-mobile} {
                letter-spacing: 4px;
                font-size: 16px;
            }
        }
        & .title {
            color: $white;
            font-weight: 400;
            font-family: $headings-font-family;
            font-size: 100px;
            line-height: 1;
            text-transform: capitalize;
            margin-top: 50px;

            @media #{$tablet-device} {
                font-size: 70px;
                margin-top: 30px;
            }
            @media #{$large-mobile} {
                font-size: 50px;
                margin-top: 10px;
            }            
            @media #{$extra-small-mobile} {
                font-size: 44px;
                letter-spacing: 0px;
            }

        }
    }
}
