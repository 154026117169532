/*====================  
    4.11 FAQ CSS
====================*/
.faq-accordion-wrapper {
    & .accordion-item {
        margin-top: 10px;
        overflow: hidden;
    }
    & .accordion-button {
        position: relative;

        font-family: $headings-font-family;
        font-size: 22px;
        font-weight: 500;

        color: $creme;
        background: $white;
        line-height: 1.4em;
        cursor: pointer;

        padding: 10px 45px 10px 0px;
        margin-bottom: 0px;

        transition: all 500ms ease;
        border: 0;
        text-align: start;
        overflow: hidden;

        @media #{$large-mobile} {
            font-size: 18px;
         }

        &::after {
            display: none;
        }

        &::before {
            content: "\e646";
            font-family: themify;
            position: absolute;
            right: 0;
            top: 0%;
            font-size: 20px;
            transform: rotate(90deg);
            transition: .4s;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }

        &.collapsed {
            color: $dark;
            position: relative;
            &::before {
                content: "\e64b";
                font-family: themify;
                position: absolute;
                right: 0;
                top: 0%;
                font-size: 20px;
                transform: rotate(0deg);
            }
        }

        &:focus {
            outline: 0;
            box-shadow: none;
            border: none;
        }


    }

    & .accordion-collapse {
        border: 0px;
        & .accordion-body {
            padding: 0;
            & p {
                display: block;
                line-height: 1.8em;
                color: $gray-300;
            }
        }
    }

    &.faq-accordion-right {
        padding-left: 45px;
        @media #{$large-mobile, $desktop-device, $tablet-device} {
            padding-left: 0px;
         }
    }
}