/*----------------------------------------*/
/*  05. Pages CSS
/*----------------------------------------*/
/*====================  
    5.1 About Page CSS
====================*/
.about-image {
    & img {
        padding-right: 150px;
        @media #{$desktop-device} {
            padding-right: 0px;
        }
        @media #{$tablet-device, $large-mobile} {
            padding-right: 100px;
            padding-left: 100px;
        }
        @media #{$small-mobile} {
            padding-right: 60px;
            padding-left: 60px;
        }
        @media #{$extra-small-mobile} {
            padding-right: 20px;
            padding-left: 20px;
        }
    }
}
.about-content {

    & .title {
        margin-top: 15px;
        margin-bottom: 40px;
        @media #{$large-mobile} {
            margin-bottom: 20px;
        }
    }

    & p {
        color: $gray-300;
        line-height: 2em;
        margin-bottom: 62px;

        @media #{$large-mobile} {
            margin-bottom: 30px;
        }
    }
}
/* Consult form */
.consult-form{
    position: relative;
    & .form-group{
        position: relative;
        margin: 0px 0px 20px;

        &:last-child {
            margin-bottom: 0px;
            margin-left: -45px;
            margin-right: -45px;
        }

        & label {
            position: relative;
            margin-bottom: 0px;
            color: $dark;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
        }
    }

    & form {
        & input[type="text"],
        & input[type="search"],
        & input[type="tel"],
        & input[type="email"],
        & input[type="range"],
        & select{
            position: relative;
            display: block;
            width: 100%;
            height: 46px;
            line-height: 28px;
            padding: 0px 0px 0px;
            font-size: 18px;
            font-style: normal;	
            background: $white;
            border-radius: 0px;
            transition: all 500ms ease;
            border: 0;
            border-bottom: 1px solid #cccccc;

            &:focus {
                border-color: $creme;
            }
        }

        & textarea{
            position: relative;
            display: block;
            width: 100%;
            height: 165px;
            line-height: 28px;
            padding: 0px 0px 0px;
            font-size: 18px;
            font-style: normal;	
            background: $white;
            border-radius: 0px;
            resize: none;
            margin-top: 8px;
            transition: all 500ms ease;
            border: 0px;

            &:focus {
                border: 0px;
            }
        }
    }
}

/*Custom Select*/
.form-group{
    & .ui-selectmenu-button{
        &.ui-button{
            width:100%;
            font-size:18px;
            font-style:normal;
            height:46px;
            padding:10px 0px 10px 0px;
            line-height:26px;
            color:#999999;
            border: none;
            border-radius:0px;
            background: $white;
            border-bottom:1px solid #cccccc;
        }
    }
    & .ui-button {
        & .ui-icon{
            background: none;
            position: relative;
            top: 2px;
            text-indent: 0px;
            color: $dark;

            &::before {
                font-family: 'themify';
                content: "\e64b";
                position: absolute;
                right: 0px;
                top: 2px !important;
                top: 13px;
                width: 10px;
                height: 20px;
                display: block;
                color: $dark;
                line-height: 20px;
                font-size: 10px;
                font-weight: normal;
                text-align: center;
                z-index: 5;
                font-weight: 800;
            }
        }

        &:focus {
            outline: 0;
            border-color: $creme;
        }
    } 
} 
.ui-menu {
    & .ui-menu-item{
        font-size:14px;

        &:last-child {
            border:none;	
        }
    }
    & .ui-menu-item-wrapper{
        position:relative;
        display:block;
        padding:8px 20px;
        font-size:14px;
        line-height:24px;

        &:hover {
            background-color: $primary;
            border-color: $primary;
        }
    }
}
.ui-state-active, .ui-widget-content {
    & .ui-state-active{
        background: $primary;
        border-color: $primary;
    }
}

// About Section Background
.about-section-bg {
    background-image: url(../images/background/about-bg.jpg);
    background-size: cover;
    padding: 300px 0px 340px;
    background-position: center;

    @media #{$desktop-device, $tablet-device} {
        padding: 150px 0;
    }

    @media #{$large-mobile} {
        padding: 100px 0;
    }

    & .about-section-title {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 1;
        & .subtitle {
            color: $white;
            font-size: 18px;
            opacity: 0.75;
            letter-spacing: 10px;
            text-transform: uppercase;
            font-family: $headings-font-family;

            @media #{$tablet-device} {
                letter-spacing: 6px;
            }
            @media #{$large-mobile} {
                letter-spacing: 4px;
                font-size: 16px;
            }
        }
        & .title {
            color: $white;
            font-weight: 400;
            font-family: $headings-font-family;
            font-size: 200px;
            line-height: 1;
            letter-spacing: 50px;
            text-transform: uppercase;
            text-indent: 50px;
            margin-top: 50px;

            @media #{$desktop-device} {
                font-size: 180px;
                letter-spacing: 30px;
                text-indent: 30px;
            }
            @media #{$tablet-device} {
                font-size: 120px;
                letter-spacing: 10px;
                text-indent: 10px;
                margin-top: 30px;
            }
            @media #{$large-mobile} {
                font-size: 100px;
                letter-spacing: 5px;
                text-indent: 5px;
                margin-top: 10px;
            }            
            @media #{$extra-small-mobile} {
                font-size: 80px;
                letter-spacing: 0px;
            }

        }
    }
}