/*====================  
    4.7 Team CSS
====================*/
.team-bg {
    position: relative;
    background-image: url(../images/background/bg-2.jpg);
    background-size: cover;
}
.team-carousel {

    & .inner-box {
        position: relative;

        & .team-content {
            position: absolute;
            left: 0px;
            bottom: 0px;
            right: 0px;
            bottom: 0px;
            padding: 25px;
            background-color: #571516;
            transition: all 900ms ease;
            transition: transform 0.4s ease;
            transform: scale(0, 1);
            transform-origin: right center;

            & .title {
                & a {
                    color: $white;
                    &:hover {
                        color: $creme;
                    }
                }
            }
            & p {
                position: relative;
                color: $white;
                opacity: .75;
                font-size: 15px;
                margin-top: 10px;
            }
            & .social-box{
                position: relative;
                margin-top: 20px;

                & li {
                    position: relative;
                    margin-right: 18px;
                    display: inline-block;

                    & a {
                        position: relative;
                        color: $white;
                        font-size: 24px;
                        transition: all 300ms ease;

                        &:hover {
                            color: $creme;
                        }
                    }
                }
            }
        }

        &:hover {
            & .team-content {
                transform: scale(1, 1);
                transform-origin: left center;
            }
        }
    }
    & .swiper-slide {
        opacity: 0;
        visibility: hidden;
    }
    & .swiper-slide-visible {
        opacity: 1;
        visibility: visible;
    }
    & .swiper-container {
        position: relative;
        margin-right: -150px;
        margin-left: -150px;
        padding: 0 150px;
        @media #{$laptop-device, 
            $desktop-device, 
            $tablet-device, 
            $large-mobile} {
            margin-right: 0px;
            margin-left: 0px;
            padding: 0px;
        }
    }
    & .team-slider-prev, & .team-slider-next {
        display: flex;
        align-items: center;
        top: 50%;
        color: $gray-400;
        transition: $transition-base;

        &:hover {
            color: $creme;
            transition: $transition-base;
        }

        &:focus{
            border: 0px;
            outline: 0px;
        }
    }
    & .team-slider-prev {
        left: 0;
        right: auto;
        @media #{$laptop-device, $desktop-device, $tablet-device} {
            left: 20px;
        }
    }
    & .team-slider-next {
        right: 0;
        left: auto;
        @media #{$laptop-device, $desktop-device, $tablet-device} {
            right: 20px;
        }
    }

}