/*====================  
    4.2 Brand Logo CSS
====================*/
.single-brand-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    & a {
        position: relative;

        & img {
            opacity: .75;
            transform: scale(.9);
            transition: $transition-base;
        }

        &:hover {
            color: $primary;
            & img {
                opacity: 1;
                transform: scale(1);
                transition: $transition-base;
                filter: invert(24%) sepia(7%) saturate(7209%) hue-rotate(322deg) brightness(84%) contrast(106%) grayscale(80%);
            }
        }
    }
}
.brand-logo-carousel {
    padding: 100px 0;
    @media #{$tablet-device} {
        padding: 80px 0;
    }
    @media #{$large-mobile} {
        padding: 60px 0;
    }
}