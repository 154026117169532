/*----------------------------------------*/
/*  02. Header CSS
/*----------------------------------------*/
/*-- Header Wrapper --*/
.header-wrapper {
    min-height: 100px;
    @media #{$extra-small-mobile} {
        min-height: 80px;
    }

    & .social-media-link {
        display: flex;
        justify-content: flex-end;
        @media #{$tablet-device, $large-mobile} {
            display: none;
        } 
    }

    & .mobile-menu-hamburger {
        display: none;
        @media #{$tablet-device, $large-mobile} {
            display: flex;
            justify-content: flex-end;
        } 
    }
}

/*-- Header Logo --*/
.header-logo {
    img {
        width: 180px;
        @media #{$tablet-device} {
            padding: 25px 0;
        }
        @media #{$large-mobile} {
            width: 180px;
            padding: 25px 0;
        }
        @media #{$extra-small-mobile} {
            width: 160px;
        }
    }
}

/*-- Main Menu & Language style --*/
.main-menu-language-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    
    @media #{$tablet-device} {
        justify-content: center;
    }
}
.main-menu {
    
    @media #{$laptop-device, $desktop-device} {
        margin-left: 25px;
    }
    & > ul {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        & > li {
            & + li {
                margin-left: 40px;
            }
            &:hover {

                & > a {
                    & > span {

                        &:before {
                            left: 0;
                            right: auto;
                            width: 100%;
                        }
                    }
                }

            }
            & > a {
                font-size: 14px;
                line-height: 100px;

                text-decoration: none;
                letter-spacing: 0.5px;
                text-transform: uppercase;

                color: $gray-300;
                font-weight: 600;
                display: block;
                position: relative;
                &:hover, &.active {
                    color: $primary;
                }

                & > span {
                    position: relative;
                    
                    &:before {
                        background-color: $primary;
                        content: "";
                        position: absolute;
                        left: auto;
                        right: 0;
                        bottom: -2px;
                        height: 2px;
                        transition: 0.4s;
                        width: 0;
                    }
                }
            }
        }
        & > li {
            position: relative;
            & .submenu {
                padding: 15px 20px;
                box-shadow: 0px 0px 10px rgba(0,0,0,0.10);
                left: -30px;
                top: 100%;
                width: 230px;
                position: absolute;
                transition: .5s;
                opacity: 0;
                visibility: hidden;
                border-radius: 10px;
                background: $white;
                z-index: 2;
                & li {
                    & a {
                        font-size: 15px;
                        color: $dark;
                        font-weight: 400;
                        display: block;
                        padding: 5px 10px;
                        line-height: 1.6;
                        transition: $transition-base;
                        position: relative;

                        &::before {
                            position: absolute;
                            top: -6px;
                            left: 8px;
                            color: transparent;
                            content: '.';
                            text-shadow: 0 0 transparent;
                            font-size: 2em;
                            line-height: 1em;
                            transition: text-shadow 0.3s, color 0.3s;
                            pointer-events: none;
                            animation: opacitychange 1s ease-in-out infinite;
                        }
                        &:hover {
                            color: $primary;
                            padding-left: 28px;
                            transition: $transition-base;
                        }
                    }

                    &:hover {
                        & a {
                            &::before {
                                opacity: 1;
                                color: #000000;
                                text-shadow: 8px 0 $primary, -8px 0 $primary;
                            }
                        }
                    }
                }
            }
            &:hover {
                & .submenu {
                    opacity: 1;
                    visibility: visible;
                    left: 0;
                    transition: .5s;
                }
            }
        }

    }
}

/*-- Mobile Menu Hamburger --*/
.mobile-menu-hamburger {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & a {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-transform: uppercase;
        & span {
            font-size: 14px;
            font-weight: 600;
            margin-right: 20px;
            @media #{$tablet-device, $large-mobile} {
                display: none;
            }
        }
        & .icon {
            font-size: 30px;
            font-weight: 400;
        }
        &:hover {
            color: $primary;
        }
    }
}

/*-- Sticky Header --*/
.header-sticky {
    &.sticky {
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
        transition: all .5s ease 0s;
        border-bottom: 1px solid rgba(0,0,0,0.075);
        animation: fadeInDown .5s ease-in-out;
        background: $white;
        
        & .header-logo {
            & img {
                padding: 24px 0;
            }
        }

        & .main-menu {
            & > ul {
                & > li {
                    & > a {
                        line-height: 100px;
                    }
                }
            }
        }
    }
}

/*-- Header top --*/
.header-top {
    padding: 14px 0px;
    border-bottom: 1px solid $gray-700;
}
.header-top-left {
    color: $gray-300;
    font-weight: 400;
    @media #{$large-mobile} {
        text-align: center;
    }
    @media #{$extra-small-mobile} {
        font-size: 13px;
    }
}
.header-top-right {
    & .info-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        @media #{$large-mobile} {
            justify-content: center;
        }
        & li{
            position: relative;
            color: $gray-300;
            margin-right:15px;
            padding-right:15px;
            display:inline-block;
            @media #{$extra-small-mobile} {
                font-size: 13px;
                margin-right: 14px;
                padding-right: 14px;

                &::before {
                    font-size: 13px !important;
                }
            }

            &::before {
                position:absolute;
                content:'/';
                right:-5px;
                top:1px;
                font-size:15px;
                color: $gray-300;
                font-weight:300;
            }

            &:last-child {
                margin-right:0px;
                padding-right:0px;
                &::before {
                    display: none;
                }
            }

            & a{
                position: relative;
                color: $gray-300;
                text-transform: initial;
                transition: $transition-base;

                &:hover {
                    color: $primary;
                }
            }
        }
    } 
}


