/*====================  
    5.2 Service Page CSS
====================*/
.service-section-bg {
    background-image: url(../images/background/service-bg.jpg);
    background-size: cover;
    padding: 200px 0px 200px;
    background-position: center;

    @media #{$desktop-device, $tablet-device} {
        padding: 150px 0;
    }

    @media #{$large-mobile} {
        padding: 100px 0;
    }


    & .service-section-title {
        z-index: 1;
        position: relative;
        & .subtitle {
            color: $white;
            font-size: 18px;
            line-height: 1.3em;
            letter-spacing: 8px;
            text-transform: uppercase;
            margin-bottom: 80px;
            font-family: $font-family-base;
            opacity: .75;

            @media #{$desktop-device} {
                margin-bottom: 20px;
            }
            @media #{$tablet-device} {
                margin-bottom: 10px;
            }
            @media #{$large-mobile} {
                margin-bottom: 10px;
                letter-spacing: 4px;
                font-size: 16px;
            }
            @media #{$extra-small-mobile} {
                margin-bottom: 10px;
                letter-spacing: 4px;
                font-size: 14px;
            }
        }
    
        & .title {
            font-size: 100px;
            font-weight: 400;
            color: $white;
            font-family: $headings-font-family;
            line-height: 1;
            margin-bottom: 70px;

            & span {
                color: $creme;
            }
            text-transform: initial;
    
            @media #{$desktop-device, $tablet-device} {
                font-size: 70px;
                margin-bottom: 40px;
            }

            @media #{$large-mobile} {
                font-size: 50px;
                margin-bottom: 20px;
            }

            @media #{$small-mobile} {
                font-size: 40px;
                margin-bottom: 20px;
            }
            
            @media #{$extra-small-mobile} {
                font-size: 30px;
            }
    
        }

        & p {
            font-size: 16px;
            color: $white;
            width: 490px;
            text-align: center;
            margin: 0 auto;
            line-height: 1.9;
            opacity: .75;

            @media #{$small-mobile} {
                font-size: 14px;
                width: 400px;
            }
            @media #{$extra-small-mobile} {
                width: 280px;
            }
        }
    }
}
/* Single Service Background */
.single-service-section-bg {
    background-image: url(../images/background/single-service-bg.jpg);
    background-size: cover;
    padding: 200px 0px 200px;
    background-position: center;

    @media #{$desktop-device, $tablet-device} {
        padding: 150px 0;
    }

    @media #{$large-mobile} {
        padding: 100px 0;
    }


    & .service-section-title {
        z-index: 1;
        position: relative;
        & .subtitle {
            color: $white;
            font-size: 18px;
            line-height: 1.3em;
            letter-spacing: 8px;
            text-transform: uppercase;
            margin-bottom: 80px;
            font-family: $font-family-base;
            opacity: .75;

            @media #{$desktop-device} {
                margin-bottom: 20px;
            }
            @media #{$tablet-device} {
                margin-bottom: 10px;
            }
            @media #{$large-mobile} {
                margin-bottom: 10px;
                letter-spacing: 4px;
                font-size: 16px;
            }
            @media #{$extra-small-mobile} {
                margin-bottom: 10px;
                letter-spacing: 4px;
                font-size: 14px;
            }
        }
    
        & .title {
            font-size: 100px;
            font-weight: 400;
            color: $white;
            font-family: $headings-font-family;
            line-height: 1;
            margin-bottom: 70px;

            & span {
                color: $creme;
            }
            text-transform: initial;
    
            @media #{$desktop-device, $tablet-device} {
                font-size: 70px;
                margin-bottom: 40px;
            }

            @media #{$large-mobile} {
                font-size: 50px;
                margin-bottom: 20px;
            }

            @media #{$small-mobile} {
                font-size: 40px;
                margin-bottom: 20px;
            }
            
            @media #{$extra-small-mobile} {
                font-size: 30px;
            }
    
        }

        & p {
            font-size: 16px;
            color: $white;
            width: 490px;
            text-align: center;
            margin: 0 auto;
            line-height: 1.9;
            opacity: .75;

            @media #{$small-mobile} {
                font-size: 14px;
                width: 400px;
            }
            @media #{$extra-small-mobile} {
                width: 280px;
            }
        }
    }
}

/* Service Detail */
.service-detail {
	position: relative;
    & .inner-box{
        position: relative;

        & .title{
            position: relative;
            color: $dark;
            line-height: 1.2em;
            margin-bottom: 20px;
        }

        & p{
            position: relative;
            color: $gray-300;
            line-height: 1.8em;
            margin-bottom: 18px;
        }

        & .tag-text{
            position: relative;
            color: $dark;
            font-size: 24px;
            margin-top: 35px;
            margin-bottom: 50px;
            padding: 30px 30px 34px 45px;
            border-left: 3px solid #d2c5b3;
            background-color: #f1efeb;
            font-family: $headings-font-family;
            line-height: 1.5;
            @media #{$large-mobile} {
                font-size: 22px;
            }
            @media #{$extra-small-mobile} {
                font-size: 18px;
                padding: 20px 35px 24px 25px;
            }
        }

        & .two-column{
            position: relative;
            margin-bottom: 25px;
            @media #{$large-mobile} {
                margin-bottom: 5px;
            }

            & .row{
                margin: 0px -35px;
                @media #{$large-mobile} {
                    margin: 0 -15px;
                }
            }

            & .column{
                padding: 0px 35px;
                @media #{$large-mobile} {
                    padding: 0 15px;
                }
            }
        }

        & .service-list{
            position: relative;
            margin-bottom: 20px;

            & li{
                position: relative;
                color: $dark;
                font-size: 14px;
                padding: 9px 0px;
                border-bottom: 1px solid #ddd;

                & span{
                    position: absolute;
                    right: 0px;
                    color: $creme;
                }

                &:last-child {
                    border-bottom: 0px;
                }
            }
        }

        & .lawyer-gallery{
            position: relative;
            margin-bottom: 40px;
            @media #{$large-mobile} {
                margin-bottom: 20px;
            }

            & .image{
                position: relative;
                margin-bottom: 30px;
            }
        }

        & .questions{
            position: relative;
            color:$dark;
            font-size: 14px;
            margin-top: 40px;
            display: inline-block;
            border-bottom: 1px solid $dark;
            @media #{$large-mobile} {
                margin-top: 20px;
            }
        }
    }
}

.sidebar-widget{
    margin-bottom:45px;
    
    & .sidebar-title{
        position:relative;
        margin-bottom:20px;

        & .title{
            position: relative;
            color: $dark;
            font-weight: 500;
            line-height: 1.3em;
            text-transform: capitalize;
        }
    }

}

.service-cat{
    position:relative;
    
    & li{
        position:relative;

        & a{
            position: relative;
            color: $dark;
            font-size: 16px;
            padding: 12px 0px;
            display: block;
            z-index: 1;
            font-weight: 700;
            text-transform: uppercase;
            transition: $transition-base;
            border-bottom: 1px solid #ddd;

            @media #{$large-mobile} {
                font-size: 14px;
                padding: 10px 0;
                font-weight: 500;
             }

            &::after {
                position: absolute;
                content: "\e649";
                right: 0px;
                top: 12px;
                color: #356f89;
                font-size: 16px;
                font-weight: 800;
                font-family: 'themify';
            }
        }
        &.active a, & a:hover {
            color: $creme;
            &::after {
                color: $creme;
                transition: $transition-base;
            }
        }

        &:last-child {
            & a {
                border-bottom: 0px;
            }
        }
    }
}

/* Reservations Widget */

.reservation-widget {
    & .widget-content{
        position: relative;
        padding: 25px 20px;
        border-radius: 5px;
        background-color: #f1efeb;
    }
} 

/* Consulation Form */

.reservation-form {
    & .form-group{
        position:relative;
        margin-bottom:25px;

        & .icon{
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            color: #024b6c;
            font-size: 22px;

            @media #{$desktop-device} {
                font-size: 16px;
                right: 10px;
             }
        }

        & input[type="text"],
        & input[type="password"],
        & input[type="tel"],
        & input[type="email"],
        & select{
            position: relative;
            display: block;
            width: 100%;
            color: $dark;
            line-height: 28px;
            padding: 10px 20px;
            height: 50px;
            font-size: 14px;
            border-radius: 4px;
            background-color: $white;
            transition: $transition-base;
            border: 1px solid $white;

            &:focus {
                border-color: $creme;
            }
        }

        & textarea {
            position: relative;
            display: block;
            width: 100%;
            line-height: 24px;
            padding: 15px 20px;
            color: $dark;
            height: 170px;
            resize: none;
            font-size: 14px;
            border-radius: 4px;
            background-color: $white;
            transition: $transition-base;
            border-color: transparent;

            &:focus {
                border-color: $creme;
            }
        }
    }
}
.sticky-top {
    top: 100px;
    z-index: 1;
}