/*====================  
    4.1 Button CSS
====================*/
/* ======== 4.1 Button CSS ========*/
.btn {
    text-transform: uppercase;
    padding: 20px 35px;
    color: $creme;
    font-size: 16px;
    font-family: $headings-font-family;
    &:focus {
        box-shadow: none;
    }

    @media #{$large-mobile} {
        padding: 15px 25px;
        font-size: 14px;
    }

     @media #{$extra-small-mobile} {
        padding: 12px 20px;
    }

    &-sm {
        padding: 5px 10px;
    }
}
/* Button Color & Style (Solid & Outline) */
@each $name, $value in $theme-colors {
    .btn-#{$name} {
        color: $value;
        border-color: $value;
        background-color: $value;

        @if $name==primary {
            color: $white;
            background-color: $primary;
        }

        @else if $name==white-gray {
            color: $primary;
        }

        @else {
            color: $white;
        }
    }
}
@each $name, $value in $theme-colors {
    .btn-hover-#{$name} {
        &:hover {
            color: $value;
            border-color: $value;
            background-color: $value;
            @if $name == light {
                color: $headings-color;
            }
            @else if $name == warning {
                color: $headings-color;
            }
            @else if $name == whited {
                color: $white;
                background-color: $creme;
                border-color: $creme;
            }
            @else {
                color: $white;
            }
        }
    }
}
/* Button Color & Style (Solid & Outline) */
@each $name, $value in $theme-colors {
    .btn-outline-#{$name} {
        color: $value;
        border-color: $value;
        background-color: $value;

        @if $name==primary {
            color: $primary;
            border: 1px solid;
            border-color: $primary;
            background-color: transparent;
        }

        @else if $name==info {
            color: $info;
            background: transparent;
            border: 1px solid $info;
        }

        @else {
            color: $white;
        }
    }
}
@each $name, $value in $theme-colors {
    .btn-outline-hover-#{$name} {
        &:hover {
            color: $value;
            border-color: $value;
            background-color: $value;
            @if $name == primary {
                color: $white;
                background-color: $creme;
                border-color: $creme;
            } 
            @else if $name == warning {
                color: $headings-color;
            } 
            @else if $name == whited {
                color: $headings-color;
            }
            @else if $name == primary {
                color: $white;
                border-color: $primary;
                background-color: $primary;
            }
            @else {
                color: $white;
            }
        }
    }
}