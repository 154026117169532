/*====================  
    4.10 Pricing CSS
====================*/
.pricing-section{
    
    & .inner-container{
        position:relative;
        border:1px solid #cccccc;
        border-bottom: 0px;
        @media #{$large-mobile} {
            border: 0px;
        }

        @media #{$tablet-device, $large-mobile} {
            border-right: 0px;
        }

        & .price-block{

            @media #{$large-mobile} {
                margin-bottom: 20px;
            }
            & .inner-box{
                border-right:1px solid #cccccc;
                border-bottom: 1px solid #cccccc;
                @media #{$large-mobile} {
                    border: 1px solid #cccccc;
                }
            }

            &:last-child {
                & .inner-box{
                    border-right:none;
                    @media #{$tablet-device, $large-mobile} {
                        border-right: 1px solid #cccccc;
                    }
                }
            }
        }
    }
}

.price-block{

    & .inner-box{
        position:relative;
        text-align:center;
        padding:105px 70px 75px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
        align-items: center;

        @media #{$desktop-device, $tablet-device, $large-mobile} {
            padding: 80px 40px;
        }
        @media #{$large-mobile} {
            padding: 80px 20px;
        }

        & .popular{
            position:absolute;
            left:50%;
            top:-1px;
            color:$creme;
            font-size:16px;
            font-weight:700;
            letter-spacing:3px;
            padding:7px 30px;
            text-transform:uppercase;
            transform:translateX(-50%);
            border-radius:0px 0px 35px 35px;
            background-color:#294148;
        }

        & .icon-box{
            position: relative;
            line-height: 5;
            margin-bottom:60px;

            & img {
                display: inline-block;
                max-width: 100%;
                height: auto;
            }
        }

        & .title{
            position:relative;
            color:$creme;
            font-size:18px;
            letter-spacing:1px;
            text-transform:uppercase;
        }

        & .price{
            position:relative;
            color:#294148;
            font-size:56px;
            font-weight:400;
            line-height:1em;
            margin-top:22px;
            font-family: $headings-font-family;

            @media #{$large-mobile} {
                font-size: 46px;
            }

            & sup{
                position:relative;
                top:-20px;
                font-size:20px;
                font-weight:400;
            }

            & sub{
                position:relative;
                font-size:20px;
                font-weight:400;
            }
        }

        & .list{
            position:relative;
            margin-top:45px;
            margin-bottom:30px;

            & li{
                position:relative;
                color:#294148;
                font-size:14px;
                padding:20px 0px;
                line-height:1.8em;
                text-transform:uppercase;
                border-top:1px solid #cccccc;
            }
        }
    }
}